.hero {
  position: relative;
  background-image: url("../../photos/download.JPG");
  background-color: black;
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.hero h1 {
  font-size: 6rem;
  margin-bottom: 3rem;
  position: relative;
  z-index: 2;
}

.hero p {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 3rem;
  max-width: 70%;
  word-wrap: break-word;
  position: relative;
  z-index: 2;
}

.hero-button {
  display: inline-block;
  padding: 1rem 2.25rem;
  background-color: #906D11;
  color: white;
  font-size: 2.4rem;
  font-weight: bold;
  text-decoration: none;
  border-radius: 16px;
  transition: background-color 0.3s, transform 0.3s;
  position: relative;
  z-index: 2;
}

.hero-button:hover {
  background-color: #b59000;
  transform: scale(1.05);
}

@media (max-width: 48rem) {
  .hero h1 {
    font-size: 4rem;
    margin-bottom: 2rem;
  }

  .hero p {
    font-size: 1.5rem;
    max-width: 90%;
  }

  .hero-button {
    font-size: 2rem;
    padding: 0.8rem 1.75rem;
  }
}

@media (max-width: 30rem) {
  .hero h1 {
    font-size: 3rem;
  }

  .hero p {
    font-size: 1.2rem;
  }

  .hero-button {
    font-size: 1.8rem;
    padding: 0.6rem 1.5rem;
  }
}
