.awards-page {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.our-awards {
  margin-top: 7rem;
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.award-section {
  margin-bottom: 3rem;
}

.award-year {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: #906D11;
}

.award-items {
  list-style-type: none;
  padding: 0;
}

.award-item {
  font-size: 1.2rem;
  margin: 0.5rem 0;
  color: #e8e8e8;
}

@media (max-width: 48rem) {
  .awards-page {
    padding: 1rem;
  }

  .our-awards {
    font-size: 2.5rem;
  }

  .award-year {
    font-size: 1.5rem;
  }

  .award-item {
    font-size: 1rem;
  }
}

@media (max-width: 30rem) {
  .our-awards {
    font-size: 2rem;
  }

  .award-year {
    font-size: 1.25rem;
  }

  .award-item {
    font-size: 0.875rem;
  }
}

.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}
