body {
  background-color: #181818;
  margin: 0;
  color: #e8e8e8;
}

.about-us {
  padding: 2rem;
  max-width: 50rem;
  margin: 0 auto;
  font-family: 'Open Sans', sans-serif;
  background-color: transparent;
}

.about-us h1 {
  margin-top: 7rem;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
}

.about-us h2 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 1.75rem;
}

.mission p {
  text-align: justify;
  font-size: 1.125rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.first-info img,
.section-image {
  width: 100%;
  border-radius: 0.5rem;
  margin: 1.5rem 0;
  border: 0.2rem solid #906D11;
}

.first-info h2 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #d1d1d1;
  font-size: 1.75rem;
}

.first-info p {
  text-align: justify;
  font-size: 1.125rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.fade-in {
  opacity: 0;
  transform: translateY(1.25rem);
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 48rem) {
  .about-us {
    padding: 1rem;
  }

  .about-us h1 {
    font-size: 2rem;
  }

  .about-us h2 {
    font-size: 1.5rem;
  }

  .mission p,
  .first-info p {
    font-size: 1rem;
  }
}

@media (max-width: 30rem) {
  .about-us h1 {
    font-size: 1.8rem;
  }

  .about-us h2 {
    font-size: 1.3rem;
  }

  .mission p,
  .first-info p {
    font-size: 0.875rem;
    line-height: 1.5;
  }

  .first-info img {
    margin: 1rem 0;
  }
}
