.awards {
  position: relative;
  background-image: url("../../photos/image5.jpg");
  background-color: white;
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.awards::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.awards h1 {
  font-size: 3rem;
  margin-bottom: 2rem;
  position: relative;
  z-index: 2;
}

.awards-list {
  list-style-type: none;
  padding: 0;
  margin: 0; 
  position: relative;
  z-index: 2;
}

.awards-list li {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.awards-button {
  margin-top: 1rem;
  display: inline-block;
  padding: 1rem 2rem;
  background-color: #906D11;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s, transform 0.3s;
  position: relative;
  z-index: 2;
}

.awards-button:hover {
  background-color: #b59000;
  transform: scale(1.05);
}

@media (max-width: 48rem) {
  .awards {
    padding: 2rem 1rem;
  }

  .awards h1 {
    font-size: 2.5rem;
  }

  .awards-list li {
    font-size: 1.25rem;
  }

  .awards-button {
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
  }
}

@media (max-width: 30rem) {
  .awards h1 {
    font-size: 2rem;
  }

  .awards-list li {
    font-size: 1rem;
  }

  .awards-button {
    font-size: 0.875rem;
    padding: 0.6rem 1.2rem;
  }
}
