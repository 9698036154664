.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #906D11;
  color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.header .logo {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 3.125rem;
  width: auto;
  margin-right: 0.625rem;
}

.logo-img {
  height: 2.188rem;
  width: auto;
}

.texts {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}

.header nav ul {
  display: flex;
  list-style: none;
  margin-right: 0.625rem;
  margin: 0;
}

.header nav ul li {
  margin: 0 1rem;
}

.social-medias {
  margin: 0 3rem;
}

.header nav ul li a {
  color: white;
  font-size: 1.6rem;
  text-decoration: none;
}

.header nav ul li a:hover {
  color: #181818;
}

.header-button {
  display: flex;
  list-style: none;
  margin: 0 1rem;
  color: white;
  font-size: 1.6rem;
  text-decoration: none;
}

.header-button:hover {
  color: #181818;
}

@media (max-width: 48rem) {
  .header {
    padding: 0.5rem;
  }

  .header nav ul li {
    margin: 0 0.5rem;
  }

  .texts {
    font-size: 1rem;
  }

  .header-button{
    margin: 0 0.5rem;
    font-size: 0.8rem;
  }

  .header nav ul li a {
    font-size: 1.4rem;
  }

  .logo-image {
    height: 2rem;
    margin-right: 0.4rem;
  }

  .logo-img {
    height: 1.5rem;
  }

  .hide-on-small {
    display: none;
  }
  
  .social-medias {
    margin: 0 1rem;
  }

  .social-medias ul li a {
    font-size: 1.4rem;
    margin: 0;
  }
}

@media (max-width: 30rem) {
  .header {
    padding: 0.25rem;
  }

  .texts {
    font-size: 0.75rem;
  }

  .header nav ul li a {
    font-size: 1.2rem;
  }

  .header-button{
    margin: 0 0.25rem;
    font-size: 0.4rem;
  }

  .logo-image {
    height: 1.75rem;
    margin-right: 0.3rem;
  }

  .logo-img {
    height: 1.25rem;
  }

  .hide-on-small {
    display: none;
  }

  .social-medias {
    margin: 0 0.6rem;
  }

  .social-medias ul li a {
    font-size: 1.2rem;
    margin: 0;
  }
}
