@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400&family=Montserrat:wght@600&display=swap');

.App {
  font-family: 'Montserrat', sans-serif;
}

p {
  font-family: 'Montserrat', sans-serif;
}

.video-container {
  display: flex;
  justify-content: center;
  margin-top: 0;
}

.video-container iframe {
  width: 100%;
  height: 620px;
}

.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 768px) {
  .video-container iframe {
    height: 400px;
  }
}

@media (max-width: 480px) {
  .video-container iframe {
    height: 250px;
  }
}
