.footer {
  padding: 1rem;
  background-color: #906D11;
  color: white;
  text-align: center;
}

.footer .social a {
  color: white;
  margin: 0 0.5rem;
  text-decoration: none;
}

@media (max-width: 48rem) {
  .footer {
    padding: 0.5rem;
  }
}

@media (max-width: 30rem) {
  .footer {
    padding: 0.25rem;
  }
}
