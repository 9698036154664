.news {
  position: relative;
  background-image: url("../../photos/image12.jpg");
  background-color: #181818;
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;
}

.news.no-background {
  background-image: none;
  background-color: #2a2a2a;
}

.news::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.news h1, .news-list, .button-container {
  position: relative;
  z-index: 2;
}

.news h1 {
  margin-top: 7rem;
  font-size: 3rem;
  margin-bottom: 1.5rem;
  color: #ffffff;
}

.news-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.news-card {
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  transition: transform 0.2s;
  color: #ffffff;
}

.news-card:hover {
  transform: scale(1.05);
}

.news-card img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 0.5rem;
}

.news-card .date {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.news-button {
  display: inline-block;
  padding: 1rem 2.25rem;
  background-color: #906D11;
  color: white;
  font-size: 2.4rem;
  font-weight: bold;
  text-decoration: none;
  border-radius: 16px;
  transition: background-color 0.3s, transform 0.3s;
}

.news-button:hover {
  background-color: #b59000;
  transform: scale(1.05);
}

.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 768px) {
  .news {
    padding: 3rem 1rem;
  }

  .news h1 {
    font-size: 2.5rem;
  }

  .news-list {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .news-button {
    font-size: 2rem;
    padding: 0.8rem 1.75rem;
  }
}

@media (max-width: 480px) {
  .news h1 {
    font-size: 2rem;
  }

  .news-button {
    font-size: 1.8rem;
    padding: 0.6rem 1.5rem;
  }
}
